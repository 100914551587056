<template>
  <div>
    <!--头部-->
    <div class="banner">
<!--      <swiper :options="swiperOption" ref="mySwiper" style="min-width:1200px">-->
<!--        <swiper-slide class="swiper-slide swiper-container-3d" v-for="(banner,index) in bannerList" :key="index">-->
          <img class="banner-img" src="https://imagepub.chinau8.cn/partner-banner.png"/>
          <div class="title1 a-bounceinT">
            城市合伙人计划
          </div>
          <div class="title2 a-bounceinL">
            城市合伙人火热招募中
          </div>
          <el-button class="a-bounceinL" type="primary" @click="showDialog">立即联系</el-button>
<!--        </swiper-slide>-->
<!--      </swiper>-->
    </div>

    <div class="div2">
      <div class="title">创业各种支持政策，一起造富</div>
      <div class="option flex flex-wrap flex-between">
        <div class="item" v-for="item in options">
          <div class="flex flex-align-center">
            <img :src="item.icon" alt=""/>
            <div class="flex item-right flex-direction-column">
              <div class="name">{{ item.name }}</div>
              <div class="content">{{ item.content }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--    <div class="div3">-->
    <!--      <div class="flex flex-between">-->
    <!--        <div class="div3-left">-->
    <!--          <div class="title">你需要一个强大的肩膀让创业少走弯路</div>-->
    <!--          <div class="content">“中国游巴网”（www.chinaU8.com）成立于2014年3月，是集行业监管、企业管理和社会化服务“三位一体”的综合性公共服务平台。-->
    <!--            具有“游巴监管、游巴大数据、游巴车管家、游巴集采、游巴出行、游巴商城”六大系统；为公众提供多样化的安全出行服务、完善的诚信评价与客诉体系，-->
    <!--            实现车源合法、便捷订车、价格透明。-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <img src="~@/assets/partner/div3-right.png" alt=""/>-->
    <!--      </div>-->
    <!--    </div>-->

    <div class="div4 flex flex-between">
      <div class="left">
        <div class="title">我要成为城市合伙人</div>
        <div class="title2">请填写信息，我将为您提供更详细的资料</div>
        <div class="border"></div>
        <div class="info flex flex-align-center">
          <img src="~@/assets/partner/icon-phone.png" alt=""/>
          <div class="phone-box">
            <div class="phone-name">代理人联系方式</div>
            <div class="phone1">400-800-3550</div>
            <div class="phone2">0311-85291868</div>
          </div>
        </div>
      </div>
      <div class="right">
        <el-input v-model="form.userName" placeholder="姓名"></el-input>
        <el-input v-model="form.phone" placeholder="手机号"></el-input>
        <div class="flex flex-align-center border">
          <div class="label">所在省市</div>
          <el-cascader
              size="large"
              :options="data"
              v-model="form.addre"
              @change="handleChange">
          </el-cascader>
        </div>
        <div class="submit-button" @click="submitForm"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {provinceAndCityData, codeToText} from "element-china-area-data";
import {submitLink} from "@/api/home"; //导入省市

export default {
  name: "partner",
  components: {},
  computed: {},
  data() {
    return {
      bannerList: [
        'https://imagepub.chinau8.cn/partner-banner.png',
      ],
      swiperOption: {
        loop: false,
        speed: 500,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        // autoplay: {
        //   delay: 4000,
        //   disableOnInteraction: false,
        // },
        autoplay: false,
      },
      options: [
        {
          icon: require("@/assets/partner/icon1.png"),
          name: "研发支持",
          content: "我们紧跟科技发展的步伐，致力于为每一位尊贵的客户提供与时俱进的支持"
        },
        {
          icon: require("@/assets/partner/icon2.png"),
          name: "客户服务支持",
          content: "提供专业的客服服务，关注客户满意度，及时与客户互动，解决客户需求"
        },
        {
          icon: require("@/assets/partner/icon3.png"),
          name: "网络推广支持",
          content: "通过搜索推广，联合门户网站，整合网络营销,逐步提升产品知名度，增加品牌曝光量"
        },
        {
          icon: require("@/assets/partner/icon4.png"),
          name: "广告支持",
          content: "行业杂志、宣传资料等，用电话、邮件的形式至渠道伙 伴、意向客户增加渠道伙伴、增加用户对品牌的好感度"
        },
      ],
      form: {
        userName: "",
        phone: "",
        addre: []
      },
      address: [],
      data: provinceAndCityData,
    };
  },
  created() {
  },
  destroyed() {
  },
  mounted() {

  },
  methods: {
    handleChange(value) {
      this.getCodeToText(null, value)
    },
    getCodeToText(codeStr, codeArray) {
      if (null === codeStr && null === codeArray) {
        return null;
      } else if (null === codeArray) {
        codeArray = codeStr.split(",");
      }
      let area = "";
      switch (codeArray.length) {
        case 1:
          area += codeToText[codeArray[0]];
          break;
        case 2:
          area += codeToText[codeArray[0]] + "/" + codeToText[codeArray[1]];
          break;
        case 3:
          area +=
              codeToText[codeArray[0]] +
              "/" +
              codeToText[codeArray[1]] +
              "/" +
              codeToText[codeArray[2]];
          break;
        default:
          break;
      }
      this.address = area
      // console.log(area)
      return area;
    },
    showDialog() {
      this.$store.dispatch("dialogShow");
    },
    submitForm() {
      if (this.form.userName === "") {
        this.$message("请先填写姓名")
        return
      }
      if (this.form.phone === "") {
        this.$message("请先填写号码")
        return
      }
      this.form.addre = this.address
      submitLink(this.form).then((res) => {
        if (res.code === 0) {
          this.$message.success('保存成功')
        }
      });
    }
  },
  beforeDestroy() {
  },
};
</script>
<style lang="scss" scoped>
.banner {
  width: 1920px;
  height: 1080px;
  background: #fff;
  position: relative;

  .banner-img {
    width: 1920px;
    height: 1080px;
    z-index: -99;
  }

  .a-bounceinL {
    animation: bounceinL 2s ease-out backwards;
    animation-delay: 1s;
  }

  .a-bounceinR {
    animation: bounceinR 2s ease-out backwards;
    animation-delay: 4s;
  }

  .title1 {
    width: 1000px;
    position: absolute;
    top: 300px;
    left: 50%;
    margin-left: -458px;
    z-index: 999;
    font-size: 128px;
    font-weight: bold;
    color: #fff;
    letter-spacing: 12px;
    line-height: 256px;
  }

  .title2 {
    width: 786px;
    position: absolute;
    top: 550px;
    left: 50%;
    margin-left: -393px;
    z-index: 999;
    font-size: 64px;
    font-weight: 400;
    color: #fff;
    letter-spacing: 6px;
    line-height: 128px;
  }

  ::v-deep {
    .el-button {
      width: 187px;
      height: 70px;
      position: absolute;
      top: 700px;
      left: 50%;
      margin-left: -93px;
      z-index: 999;
      background: linear-gradient(45deg, #217EFF 0%, #124FED 100%);
      border-radius: 8px;
      font-size: 28px;
      letter-spacing: 4px;
      font-weight: bold;
      color: #FFFFFF;
    }
  }


}

.div2 {
  width: 1920px;
  height: 674px;
  background: url("~@/assets/partner/div2-bg.png") no-repeat;
  background-size: 100% 100%;
  padding: 70px 300px;
  box-sizing: border-box;

  .title {
    font-size: 36px;
    font-weight: bold;
    color: #333333;
    line-height: 42px;
    margin-bottom: 82px;
  }

  .option {
    .item {
      width: 630px;
      height: 157px;
      background: rgba(255, 255, 255, 0.8);
      box-shadow: 0px 0px 9px 0px rgba(145, 172, 240, 0.17);
      border-radius: 8px 8px 8px 8px;
      margin-bottom: 46px;
      padding: 24px;
      box-sizing: border-box;

      img {
        width: 62px;
        height: 62px;
        margin-right: 24px;
      }

      .item-right {
        align-items: baseline;

        .name {
          font-size: 26px;
          font-weight: bold;
          color: #333333;
          line-height: 26px;
          margin-bottom: 17px;
        }

        .content {
          font-size: 20px;
          font-weight: 400;
          color: #333333;
          line-height: 33px;
          text-align: left;
        }
      }


    }
  }
}

.div3 {
  width: 1920px;
  height: 534px;
  box-sizing: border-box;
  padding: 76px 180px;
  background: url("~@/assets/partner/div3-bg.png") no-repeat;
  background-size: 100% 100%;

  .div3-left {
    width: 52%;
    text-align: left;

    .title {
      font-size: 40px;
      font-weight: bold;
      color: #333333;
      line-height: 40px;
      margin-bottom: 60px;
    }

    .content {
      font-size: 24px;
      text-indent: 2em;
      font-weight: 400;
      color: #333333;
      line-height: 48px;
    }

  }

  img {
    width: 571px;
    height: 340px;
  }
}

.div4 {
  width: 1920px;
  height: 713px;
  box-sizing: border-box;
  padding: 124px 180px;
  background: url("~@/assets/partner/div4-bg.png") no-repeat;
  background-size: 100% 100%;

  .left {
    width: 40%;
    text-align: left;

    .title {
      font-size: 52px;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 52px;
      margin-bottom: 32px;
    }

    .title2 {
      font-size: 32px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 38px;
      margin-bottom: 34px;
    }

    .border {
      width: 154px;
      height: 5px;
      background: #D9D9D9;
      margin-bottom: 56px;
    }

    .info {
      img {
        width: 58px;
        height: 58px;
        margin-right: 26px;
        position: relative;
        top: -20px;
      }

      .phone-box {

        .phone-name {
          font-size: 24px;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 28px;
          margin-bottom: 15px;
        }

        .phone1, .phone2 {
          height: 48px;
          font-size: 32px;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 38px;
          margin-bottom: 15px;
        }

      }

    }
  }

  .right {
    width: 673px;
    height: 444px;
    background: #202738;
    opacity: 1;
    border: 1px solid #FFFFFF;
    padding: 30px 115px 50px;
    box-sizing: border-box;
    position: relative;

    .border {
      border-bottom: 1px solid #fff;
      padding-left: 14px;
      margin-bottom: 40px;
    }

    .label {
      font-size: 20px;
      color: #DCDFE6;
      opacity: .8;
    }

    .submit-button {
      width: 467px;
      height: 70px;
      background: url("~@/assets/partner/submit-button.png") no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
      position: absolute;
      z-index: 1000;
    }

    ::v-deep {
      .el-input__inner {
        height: 80px;
        font-size: 20px;
        color: #fff;
        background: none;
        border: none;
        border-bottom: 1px solid #fff;
        border-radius: 0;
      }

      .el-cascader {
        .el-input__inner {
          border-bottom: none;
        }
      }
    }
  }
}

</style>
